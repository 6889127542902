<!--
 * @Author: 陈远锋
 * @Date: 2022-03-11 16:31:36
 * @LastEditTime: 2022-04-08 13:12:31
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\coupon_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row">
            <!-- <template slot-scope="{ row }" slot="goods_name">
                {{row.agent_goods.goods.goods_name}}
            </template> -->
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            tableData: [],
            columns2: [
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '产品名字', key: "goods_name" },
                { align: "center", title: '券码', key: 'code' }, {
                    align: "center",
                    title: '价格',
                    key: 'price'
                },
                { align: "center", title: '生效时间', key: 'effective_time' },
                { align: "center", title: '失效时间', key: 'expires_time' },
                { align: "center", title: '状态', key: 'status_desc' }
            ]
        }
    }
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
}
</style>
