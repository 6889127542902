var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{style:({ margin: '20px 10px' })},[_c('router-link',{staticStyle:{"float":"right"},attrs:{"to":"/pages/agent_goods_contacts"}},[_vm._v(" 三方商品绑定列表 ")]),_c('BreadcrumbItem',[_vm._v("代理商商品管理")]),_c('BreadcrumbItem',[_vm._v("代理商商品列表")])],1),_c('loading',{attrs:{"loading":_vm.loading,"error":_vm.error}}),_c('Select',{staticStyle:{"margin":"10px","width":"250px"},on:{"on-change":_vm.userOnChange},model:{value:(_vm.selectCompanyId),callback:function ($$v) {_vm.selectCompanyId=$$v},expression:"selectCompanyId"}},_vm._l((_vm.companies),function(company){return _c('Option',{key:company.id,attrs:{"value":company.id}},[_vm._v(_vm._s(company.short_name))])}),1),(_vm.selectCompanyId)?_c('Table',{ref:"selection",attrs:{"border":"","stripe":"","columns":_vm.columns1,"data":_vm.baseGoods},on:{"on-selection-change":_vm.onSelectionChange},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.name))])]}},{key:"agent_sale_price",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editAgentSalePrice),callback:function ($$v) {_vm.editAgentSalePrice=$$v},expression:"editAgentSalePrice"}}):_c('span',[_vm._v(_vm._s(row.agent_sale_price))])]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('div',[_c('Button',{staticStyle:{"margin-right":"6px"},on:{"click":function($event){return _vm.handleSave(row, index)}}},[_vm._v("保存")]),_c('Button',{on:{"click":function($event){_vm.editIndex = -1}}},[_vm._v("取消")])],1):_c('div',[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.handleEdit(row, index)}}},[_vm._v("修改")])],1)]}}],null,false,427757665)}):_vm._e(),_c('Row',[_c('Button',{staticStyle:{"margin":"10px"},on:{"click":function($event){return _vm.handleSelectAll(true)}}},[_vm._v("全选")]),_c('Button',{ref:"buttonSubmitSave",staticStyle:{"margin":"10px"},attrs:{"type":"primary","disabled":_vm.dis},on:{"click":_vm.handleSubmitSave}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }