<!--
 * @Author: 陈远锋
 * @Date: 2022-02-15 14:56:47
 * @LastEditTime: 2022-02-18 14:42:52
 * @FilePath: \qyOut\src\Home.vue
 * @Description: Do not edit
-->




<template>
  <div>
    <Breadcrumb
      separator="<b>></b>"
      class="layout-nav3"
      style="margin-left: 12px"
    >
      <BreadcrumbItem to="/components/breadcrumb"
        >分销商/订单管理</BreadcrumbItem
      >
      <BreadcrumbItem>订单列表</BreadcrumbItem>
    </Breadcrumb>
    <Content :style="{ minHeight: '200px', background: '#fff' }">
      <div
        :style="{
          background: '#f5f7f9 ',
          margin: '12px',
          padding: '14px',
        }"
      >
        <Row :gutter="16">
          <Col span="6">
            <DatePicker
              type="daterange"
              placement="bottom-start"
              placeholder="开始日期至结束日期"
              style="width: 100%"
            ></DatePicker>
          </Col>
          <Col span="6">
            <div>
              <Select v-model="model1" style="width: 100%">
                <Option
                  v-for="item in cityList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
          </Col>
          <Col span="6">
            <div>
              <Select v-model="model1" style="width: 100%">
                <Option
                  v-for="item in cityList"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
          </Col>
          <Col span="6">
            <Input v-model="value" placeholder="商户订单号" />
          </Col>
          <Col span="6" :style="{ margin: '10px 0 0 0' }">
            <Input v-model="value1" placeholder="充值账号" />
          </Col>
        </Row>
        <Row>
          <div style="margin-top: 10px">
            <Button style="float: left" type="primary">
              <Icon type="md-download" />
              导出excel</Button
            >
            <Button style="margin-left: 10px" type="primary">
              <Icon type="ios-search" />
              查询</Button
            >
          </div>
        </Row>
      </div>
      <div style="margin: 0 12px">
        <Table border :columns="columns1" :data="data1"></Table>
      </div>
      <div
        :style="{
          background: '#f5f7f9 ',
          margin: '12px',
          padding: '14px',
          height: '50px',
        }"
      >
        <Page
          style="float: right"
          :total="100"
          size="small"
          show-total
          show-sizer
        />
      </div>
    </Content>
  </div>
</template>
<script>
export default {
  
  components: {
   
  },
  data() {
    return {
      value: "",
      value2: "",
      value1: "",
      value8: "",
      value9: "",
      
      columns1: [
        {
          title: "平台订单号",
          key: "Platform",
        },
        {
          title: "商户订单号",
          key: "Merchant",
        },
        {
          title: "充值账号",
          key: "Account",
        },
        {
          title: "订单商品",
          key: "Order",
        },
        {
          title: "价格",
          key: "Price",
        },
        {
          title: "购买数量",
          key: "Purchase",
        },
        {
          title: "订单总额",
          key: "total",
        },
        {
          title: "订单状态",
          key: "status",
        },
        {
          title: "支付状态",
          key: "Payment",
        },
        {
          title: "创建时间",
          key: "time",
        },
      ],
      data1: [
        {
          Platform: "348048374270009345",
          Merchant: "BLUE29509438175969280",
          Account: 13857609736,
          Order: "QQ音乐绿钻月卡",
          Price: 11.4,
          Purchase: 1,
          total: 11.4,
          status: "全部失败",
          Payment: "全部退款",
          time: "2022-02-15 10:19:58",
          
        },
        { 
          Platform: "348048374270009345",
          Merchant: "BLUE29509438175969280",
          Account: 13857609736,
          Order: "QQ音乐绿钻月卡",
          Price: 11.4,
          Purchase: 1,
          total: 11.4,
          status: "全部失败",
          Payment: "全部退款",
          time: "2022-02-15 10:19:58",
        },
        
      ],
       cityList: [
                    {
                        value: 'New York',
                        label: 'New York'
                    },
                    {
                        value: 'London',
                        label: 'London'
                    },
                    {
                        value: 'Sydney',
                        label: 'Sydney'
                    },
                    {
                        value: 'Ottawa',
                        label: 'Ottawa'
                    },
                    {
                        value: 'Paris',
                        label: 'Paris'
                    },
                    {
                        value: 'Canberra',
                        label: 'Canberra'
                    }
                ],
                model1: ''
    };
  },
};
</script>
<style scoped>
.layout-nav {
  width: 420px;
  margin: 0 auto;
  float: right;
  color: #f5f7f9;
}
.layout-nav2 {
  margin-left: 200px;
}
.layout-nav3 {
  text-align: left;
  margin: 8px 0;
}
.box {
  background: #ffffff;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  border: 1px solid #dbd9d9;
}
</style>