<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:26:22
 * @LastEditTime: 2022-04-02 09:57:25
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\coupons.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>代理券管理</BreadcrumbItem>
            <BreadcrumbItem>优惠券列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <DatePicker style="margin: 10px;" type="daterange" placeholder="请选择查询时间" @on-change="dateOnChange"></DatePicker>

        <Table border stripe :columns="columns1" :data="orders">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="copy(row, index)">复制券码</Button>
            </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import loading from '../loading.vue';
import expandRow from './coupon_expand.vue';

export default {
    components: { loading },
    data () {
        return {
            loading: false,
            error: null,
            orders: [],
            pages: {},
            selectForm: {},
            columns1: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row.coupons_code
                            }
                        })
                    }
                },
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '代理商', slot: 'company_name' },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '券码名称', key: 'goods_name' },
                { align: "center", title: '数量', key: 'num', width: 80 },
                { align: "center", title: '单价', key: 'unit_price', width: 100 },
                { align: "center", title: '金额', key: 'total', width: 100 },
                { align: "center", title: '状态', key: 'status_desc', width: 100 },
                { title: "操作", slot: "action", width: 150, align: "center", },
            ],
            copyValue: ''
        }
    },
    created () {
        this.fetchData();
    },
    methods: {
        copy (data) {
            console.log("date=>", data);
            this.copyValue = '';
            data.coupons_code.forEach(code => {
                console.log('code', code.code);
                this.copyValue += code.code + "\r\n";
            })
            console.log('111', this.$refs.copyCode);
            console.log('this.copyValue', this.copyValue);

            this.$copyText(this.copyValue).then(function (e) {
                alert('券码已复制到粘贴板');
                console.log(e)
            }, function (e) {
                alert('复制出错，请手工复制!');
                console.log(e)
            })
        },
        pageOnChange: function (page) {
            this.pages.links.forEach(link => {
                if (link.label == page) {
                    this.fetchData(link.url);
                    return;
                }
            })
        },
        dateOnChange (date) {
            console.log('date', date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
            this.fetchData('couponsOrders/list', this.selectForm);
        },
        fetchData (url = 'couponsOrders/list', params = {}) {
            let data = {};
            for (let key in params) {
                console.log('param[key],', params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.axios.get(url, data).then(res => {
                console.log('order/list res', res);
                res.data.coupons_order.forEach(order => {
                    console.log('order=>', order);
                    if (order.agent_goods.length >= 1) {
                        order.goods_name = order.agent_goods[0].goods.goods_name;
                    }
                })
                this.orders = res.data.coupons_order;

                this.pages = res.data.pages;
            })
        }
    }
}
</script>
