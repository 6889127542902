<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-15 14:48:27
 * @FilePath: \qyOut\src\pages\goods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/goods_create" style="float:right;">
                新增基础商品
            </router-link>
            <BreadcrumbItem>基础商品</BreadcrumbItem>
            <BreadcrumbItem>基础商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <div v-if="goods" class="content">
            <Table border stripe :columns="columns1" :data="goods">
                <template slot-scope="{ row }" slot="name">
                    <strong>{{ row.name }}</strong>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
                    <router-link v-if="row.type == 2" :to="'/pages/goods_box_goods_edit/'+row.id" style="border: 1px solid blue; margin-left: 6px; padding: 2px; border-radius: 4px; height: 40px;">
                        权益包商品
                    </router-link>
                </template>
            </Table>
        </div>
        <expandRow style="display: none"></expandRow>
    </div>
</template>
<script>
import expandRow from './supplier_goods_expand.vue';
import loading from '../loading.vue';
export default {
    components: { expandRow, loading },
    data () {
        return {
            loading: false,
            goods: null,
            error: null,
            columns1: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row.supplier_goods
                            }
                        })
                    }
                },
                /* { title: "时间", key: "updated_at", align: "center", }, */
                /* { align: "center", title: "ID", key: "id", width: 80 }, */
                { align: "center", title: "分类ID", key: "goods_type_id", width: 80 },
                { align: "center", title: "商品编号", key: "goods_no" },
                { align: "center", title: "商品类型", key: "type_desc" },
                { align: "center", title: "名称", key: "goods_name" },
                { align: "center", title: "原价", key: "original_price", width: 100 },
                { align: "center", title: "上架", key: "sale_desc", },
                {
                    align: "center",
                    title: "状态",
                    key: "status_desc",
                },
                { title: "操作", slot: "action", align: "center", },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/goods_edit/" + row.id);
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("goods/list").then((res) => {
                console.log("res", res);
                this.goods = res.data.goods;
                this.loading = false;
                this.goods.forEach(element => {
                    element._expanded = false;
                });
            });
        },
    },
};
</script>
