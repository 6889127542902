<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-04-08 11:44:58
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\companies\index.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/companies_create" style="float:right;">
                新增公司
            </router-link>
            <BreadcrumbItem>代理商公司管理</BreadcrumbItem>
            <BreadcrumbItem>公司列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <div v-if="companies" class="content">
            <Table border stripe :columns="columns1" :data="companies">
                <template slot-scope="{ row, index }" slot="company_name">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.company_name" />
                    <span v-else>{{ row.company_name }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="short_name">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.short_name" />
                    <span v-else>{{ row.short_name }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="contact">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.contact" />
                    <span v-else>{{ row.contact }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="phone">
                    <Input v-if="editIndex === index" type="text" v-model="editCompany.phone" />
                    <span v-else>{{ row.phone }}</span>
                </template>

                <template slot-scope="{ row , index}" slot="facility">
                    <div v-if="userInfo.isSuperAdmin">
                        <Input v-if="editIndex === index" type="text" v-model="editCompany.facility" />
                        <span v-else>{{ row.facility }}</span>
                    </div>
                    <div v-else>
                        <span>{{ row.facility }}</span>
                    </div>
                </template>

                <template slot-scope="{ row, index }" slot="action">
                    <div v-if="editIndex === index">
                        <Button @click="handleSave(row, index)" type="primary" style="margin-right: 6px">保存</Button>
                        <Button @click="editIndex = -1">取消</Button>
                    </div>
                    <div v-else>
                        <Button type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                        <router-link :to="'/pages/companies_transfer_deposit/'+row.id" v-if="userInfo.company_id != row.id">
                            <Button type="primary" size="small" style="margin-right: 5px">转账</Button>
                        </router-link>
                        <router-link :to="'/pages/companies_add_balance/'+row.id" v-if="userInfo.isSuperAdmin">
                            <Button type="primary" size="small" style="margin: 5px">超级管理直接加款</Button>
                        </router-link>
                    </div>
                </template>
            </Table>
        </div>
        <expandRow style="display: none"></expandRow>
    </div>
</template>
<script>
import { mapState } from "vuex";
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            companies: null,
            error: null,
            editCompany: {
                phone: '',
                facility: '', company_name: '', short_name: '', contact: ''
            },
            columns1: [
                /*  { title: "时间", key: "updated_at", align: "center", }, */
                /* { align: "center", title: "ID", key: "id", width: 80 }, */
                { align: "center", title: "公司名称", slot: 'company_name' },
                { align: "center", title: "公司简称", slot: 'short_name' },
                { align: "center", title: "联系人", slot: 'contact' },
                { align: "center", title: "联系电话", width: 150, slot: 'phone' },
                { align: "right", title: "预存款", key: 'balance', width: 100 },
                { align: "right", title: "授信额", width: 100, slot: 'facility' },
                { title: "操作", slot: "action", width: 200, align: "center", },
            ],
            editIndex: -1,
        };
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo", 'balance', 'companyInfo'
    ]),
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        handleEdit (row, index) {
            this.editId = row.id;
            this.editIndex = index;
            this.editCompany = row;
            console.log('editCompany=>', this.editCompany);
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;

            this.loading = true;
            this.axios.patch('companies/' + this.editId, this.editCompany).then((res) => {
                console.log("role/edit res=>", res);
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row = this.editCompany;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("companies").then((res) => {
                console.log("res", res);
                this.companies = res.data.companies;
                this.loading = false;
            });
        },
    },
};
</script>
