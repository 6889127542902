<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-06 13:44:53
 * @FilePath: \qyOut\src\pages\goods\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <p>商品添加</p>
        <div v-if="loading" class="loading">Loading...</div>

        <div v-if="error" class="error">
            {{ error }}
        </div>

        <formPage
            :agentGoods="agentGoods"
            v-on:handleSubmit="handleSubmit"
        ></formPage>
    </div>
</template>
<script>
import formPage from "./form.vue";
export default {
    components: { formPage },
    data() {
        return {
            agentGoods: {},
            loading: false,
            error: null,
        };
    },
    created() {
        console.log("created");
        this.fetchData();
        console.log(this.$route.params);
    },
    methods: {
        handleSubmit(agentGoods) {
            // 验证通过
            console.log("handleSubmit 开始");
            this.loading = true;
            this.error = null;
            this.axios.post("agentGoods/create", agentGoods).then((res) => {
                console.log("新建返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success("添加成功");
                    setTimeout(() => {
                        this.$router.push("/pages/agentGoods_create");
                    }, 2000);
                }
            });
        },
        fetchData() {
            this.axios.get("goodsTypes/list").then((res) => {
                this.goods_types = res.data.goods_types;
            });
        },
    },
};
</script>
