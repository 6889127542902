<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 21:20:13
 * @LastEditTime: 2022-04-01 14:55:55
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\bills.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>账单管理</BreadcrumbItem>
            <BreadcrumbItem>资金变动列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="date">
                <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
            </FormItem>
            <FormItem prop="pm">
                <RadioGroup v-model="selectForm.pm">
                    <Radio label="1">进</Radio>
                    <Radio label="0">出</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
            </FormItem>
        </Form>
        <Table border stripe :columns="columns1" :data="bills">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.short_name }}
            </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            selectForm: {},
            loading: false,
            error: null,
            bills: [],
            pages: {},
            columns1: [
                { title: "时间", key: "updated_at", align: "center", },
                { title: "代理商", align: "center", slot: "company_name" },
                { align: "right", title: '变动金额', key: 'number' },
                { align: "right", title: '余额', key: 'balance' },
                { align: "right", title: '备注', key: 'mark' }
            ]
        }
    },
    created () {
        this.fetchData('companies/bills');
    },
    methods: {
        dateOnChange (date) {
            console.log(date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
        },
        handleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    console.log("this.selectForm=>", this.selectForm);
                    this.fetchData('companies/bills', this.selectForm);
                } else {
                    this.$Message.error('出错了');
                }
            })
        },
        pageOnChange: function (page) {
            this.pages.links.forEach(link => {
                if (link.label == page) {
                    this.fetchData(link.url);
                    return;
                }
            })
        },
        fetchData: function (url, params) {
            let data = {};
            for (let key in params) {
                console.log('param[key],', key, params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.loading = true;
            this.axios.get(url, data).then(res => {
                console.log('companies/list res', res);
                this.bills = res.data.bills;
                this.pages = res.data.pages;
                this.loading = false;
            })
        }
    }
}
</script>
