<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 14:14:05
 * @LastEditTime: 2022-04-06 13:31:12
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\place_order.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>订单管理</BreadcrumbItem>
            <BreadcrumbItem>批量下单</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="formItem" :model="formItem" :rules="ruleValidate" label-position="right" :label-width="200">
            <FormItem label="分类">
                <Select v-model="selectedGoodsType" @on-change="goodsTypeOnChange">
                    <Option v-for="item in goodsTypes" :value="item.code" :key="item.code">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="产品" prop="agent_goods_id">
                <Select v-model="formItem.agent_goods_id" @on-change="goodsSelected" :label-in-value="true">
                    <Option v-for="item in goods" :value="item.id" :key="item.id">{{ item.goods_name }} --面额 [{{
                            item.original_price
                        }}] ---售价[{{ item.sale_price }}]
                    </Option>
                </Select>
            </FormItem>

            <FormItem label="账号" prop="text_account">
                <Input v-model="formItem.text_account" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入充值账号，一行一个."></Input>
            </FormItem>
            <FormItem>
                <Button ref="button" type="primary" @click="showModal1" :disabled="butDisabled">提交</Button>
            </FormItem>
        </Form>

        <h1 style="text-align: center;">最近的订单</h1>
        <Table border stripe :columns="ordersColumns" :data="orders">
            <template slot-scope="{ row }" slot="name">
                <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
        <Modal v-model="modal1" title="请您确认订单信息" @on-ok="formHandleSubmit" @on-cancel="modal1 = false">
            <p>产品：{{ selectedGoods.label }}</p>
            <p></p>
            <p v-for="(account, index) in formItem.text_account&&formItem.text_account.split('\n', 100)" :key="index">账号：{{account}}</p>
        </Modal>
    </div>
</template>
<script>
import expandRow from './batch_orders_expand.vue';
import loading from '../loading.vue';
import { mapState } from "vuex";
export default {
    components: { loading },
    data () {
        return {
            modal1: false,
            selectedGoods: {},
            loading: false,
            error: null,
            goods: [],
            goodsTypes: [],
            formItem: {},
            selectedGoodsType: 0,
            orders: [],
            ruleValidate: {
                agent_goods_id: [
                    {
                        type: "integer",
                        required: true,
                        message: "请选择要要充值的产品",
                        trigger: "blur"
                    },
                ],
                text_account: [
                    { required: true, message: "账号不能为空", trigger: "blur" },
                ],
            },
            ordersColumns: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        let order = params.row.order;
                        return h(expandRow, {
                            props: {
                                row: order
                            }
                        })
                    }
                },
                {
                    title: "时间",
                    key: "updated_at",
                    align: "center",
                },
                { title: '产品名', key: 'goods_name', align: "center", },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '合计数量', key: 'total_num', width: "110" }, { align: "center", title: '合计金额', key: 'total' },
                { align: "center", title: '成功数量', key: 'success_num', width: "110" },
                { align: "center", title: '失败数量', key: 'error_num', width: "110" }
            ],
            butDisabled: false
        }
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo"
    ]),
    created () {
        this.fetchData();
    },
    methods: {
        showModal1 () {
            this.$refs['formItem'].validate((valid) => {
                if (valid) {
                    console.log(this.formItem.num);
                    this.modal1 = true;
                } else {
                    console.log('验证没有通过');
                    this.$Message.error('请正确填写表单！')
                }
            })
        },
        goodsSelected (item) {
            console.log('item=>', item);
            this.selectedGoods = item;
        },
        goodsTypeOnChange: function (code) {
            console.log('code=>', code);
            this.axios.get('agentGoods/list?type=0&goods_type_code=' + code + "&company_id=" + this.userInfo.company_id).then(res => {
                console.log('agentGoods/list res=>', res);
                this.goods = res.data.agent_goods;
            })
        },
        formHandleSubmit: function (name = 'formItem') {
            console.log('this.formItem=>', this.formItem);
            this.butDisabled = true;
            setTimeout(() => {
                this.butDisabled = false;
            }, 3000);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.formItem.account = this.formItem.text_account.split('\n', 100);
                    this.axios.post('perpaidOrders/store', this.formItem).then(res => {
                        console.log('批量下单返回', res);
                        if (res.data.status == 200) {
                            this.loading = false;
                            this.$Message.success('下单成功');
                            this.axios.get("userInfo").then((res) => {
                                console.log("login res", res);
                                if (res.data.status === 200) {
                                    console.log("ok");
                                    this.$store.commit("UPDATE_USERINFO", res.data.user);
                                    this.$store.commit("UPDATE_BALANCE", res.data.user.company.balance);
                                    this.$store.commit("UPDATE_VERSION", res.data.user.company.version);
                                    this.$store.commit("UPDATE_COMPANY_INFO", res.data.user.company);
                                }
                            })
                            setTimeout(() => {
                                this.$router.push("/pages/member_batch_orders");
                            }, 2000);
                        } else if (res.data.status == 1008) {
                            this.$Message.error("您余额不足!!");
                        } else {
                            console.log(res.data);
                            this.$Message.error("下单出错了, 原因=>" + res.data.msg);
                        }
                    })
                } else {
                    this.$Message.error("请正确填写表单");
                }
            })
        },
        fetchData: function () {
            this.axios.get('goodsTypes/list?type=0').then(res => {
                this.goodsTypes = res.data.goods_types;
            })
            this.axios.get('perpaidOrders/list').then(res => {
                console.log('order/list res', res);
                this.orders = res.data.orders;
            })
        }
    }
}
</script>
