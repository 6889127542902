<!--
 * @Author: 陈远锋
 * @Date: 2022-03-09 14:46:42
 * @LastEditTime: 2022-04-01 14:23:37
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\user\bills.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>账单管理</BreadcrumbItem>
            <BreadcrumbItem>资金变动列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="uid">
                <Select @on-change="userOnChange" style="margin-right: 6px; width: 250px">
                    <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.short_name }}</Option>
                </Select>
            </FormItem>
            <FormItem prop="date">
                <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
            </FormItem>
        </Form>
        <Table border stripe :columns="columns1" :data="bills">
            <template slot-scope="{ row }" slot="company_short_name">
                {{ row.company.short_name }}
            </template>
        </Table>
        <Page style="margin: 10px; text-align: center" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            error: null,
            bills: [],
            companies: [],
            pages: {},
            selectForm: {},
            columns1: [
                { title: "时间", key: "updated_at", align: "center", width: 200 },
                { title: "ID", key: "id", align: "center", width: 100 },
                { title: "代理公司", align: "center", width: 200, slot: 'company_short_name' },
                { align: "right", title: "变动金额", key: "number", width: 120, },
                { align: "right", title: "余额", key: "balance", width: 120 },
                { align: "right", title: "备注", key: "mark", width: 300 },
            ],
        };
    },
    created () {
        this.fetchData();
    },
    methods: {
        dateOnChange (date) {
            console.log(date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
        },
        pageOnChange: function (page) {
            this.pages.links.forEach((link) => {
                if (link.label == page) {
                    this.fetchBills(link.url);
                    return;
                }
            });
        },
        handleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.fetchBills("companies/bills", this.selectForm);
                } else {
                    this.$Message.error("出错了");
                }
            });
        },
        userOnChange: function (companyId) {
            this.error = null;
            this.loading = true;
            this.bills.length = 0;
            this.selectForm.company_id = companyId;
            this.fetchBills("companies/bills", this.selectForm);
        },
        fetchBills: function (url = "companies/bills", params) {
            let data = {};
            for (let key in params) {
                console.log("param[key]", key, params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.loading = true;
            this.axios.get(url, data).then((res) => {
                this.bills = res.data.bills;
                this.pages = res.data.pages;
                this.loading = false;
            });
        },
        fetchData: function () {
            this.fetchBills();
            this.axios.get("companies").then((res) => {
                console.log("companies 读取回来的数据=>", res.data);
                this.companies = res.data.companies;
                this.companies.push({
                    id: 0,
                    name: "请选择代理公司",
                });
                this.loading = false;
            }).catch((error) => {
                this.error = error;
                this.loading = false;
            });
        },
    },
};
</script>
