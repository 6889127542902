/*
 * @Author: 陈远锋
 * @Date: 2022-02-14 17:00:12
 * @LastEditTime: 2022-06-27 13:58:53
 * @FilePath: \qyOut\src\main.js
 * @Description: Do not edit
 */
import Vue from 'vue';
import App from './App.vue';
//import Login from './Login.vue';
//import VueRouter from 'vue-router';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.config.productionTip = false;

import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store'

import { HTTP_REQUEST_URL } from '@/config/app.js';
axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'https://qy.zjgnwl.com/api/';
axios.defaults.baseURL = HTTP_REQUEST_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
	console.log("interceptors.response =>", response);
	// 对响应数据做点什么
	return response;
}, function (error) {
	// 对响应错误做点什么
	console.log("interceptors.error =>", error);
	console.log("interceptors.response =>", error.response);
	console.log("interceptors.response.status =>", error.response.status);
	localStorage.clear();
	return Promise.reject(error);
});

Vue.use(VueAxios, axios);

import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);



// 0. 如果使用模块化机制编程，导入Vue和VueRouter，要调用 Vue.use(VueRouter)

Vue.use(ViewUI);

import router from './routes';

//router.beforeEach((to, from, next) => {
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	console.log('to=>', to);
	console.log('from=>', from);
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

