<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/agent_goods_list" style="float:right;">
                代理商商品列表
            </router-link>
            <div @click="createFormSwitch=true" style="float:right; margin: 0 10px;">
                添加三方商品绑定
            </div>
            <BreadcrumbItem>代理商商品管理</BreadcrumbItem>
            <BreadcrumbItem>三方商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form ref="createObject" :model="createObject" :rules="ruleValidate" label-position="right" :label-width="200" v-if="createFormSwitch">
            <FormItem label="代理公司">
                <RadioGroup @on-change="companySelectChanged">
                    <Radio v-for="(company, ind) of companies" :key="ind" :label="company.id">
                        <span>{{ company.company_name }}</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="店铺" prop="shop_id">
                <RadioGroup v-model="createObject.shop_id">
                    <Radio v-for="(shop, ind) of shops" :key="ind" :label="shop.id">
                        <span>{{ shop.shop_name }}</span>
                    </Radio>
                </RadioGroup>
            </FormItem>

            <FormItem label="产品" prop="agent_goods_id">
                <RadioGroup v-model="createObject.agent_goods_id">
                    <Radio v-for="(goods, ind) of goodsList" :key="ind" :label="goods.id" style="width: 250px;">
                        <span>{{ goods.goods_name }}</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="三方平台商品编码" prop="other_goods_no">
                <Input v-model="createObject.other_goods_no" placeholder="请输入三方平台商品编码"></Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="formHandleSubmit('createObject')">提交</Button>
                <Button style="margin-left: 8px" @click="createFormSwitch=false">取消</Button>
            </FormItem>
        </Form>
        <Table border stripe ref="selection" :columns="columns1" :data="contacts">
            <template slot-scope="{ row, index }" slot="other_goods_no">
                <Input type="text" v-model="editObject.other_goods_no" v-if="editIndex === index" />
                <span v-else>{{ row.other_goods_no }}</span>
            </template>
            <template slot-scope="{ row }" slot="origin_name">
                {{ row.origin.origin_name}}
                <div v-if="editIndex === index">
                    <RadioGroup v-model="row.agent_goods.goods.goods_no">
                        <Radio v-for="(baseGoods, baseInd) of goodsList" :key="baseInd" :label="baseGoods.goods_no" style="padding: 0px; margin: 5px;width: 100%;">
                            {{ baseGoods.goods_name }}-{{baseGoods.agent_goods_id}}
                        </Radio>
                    </RadioGroup>
                </div>
                <span v-else>{{ row.origin.origin_name}}</span>
            </template>
            <template slot-scope="{ row, index }" slot="goods_name">
                <div v-if="editIndex === index">
                    <RadioGroup v-model="row.agent_goods_id">
                        <Radio v-for="(baseGoods, baseInd) of goodsList" :key="baseInd" :label="baseGoods.id" style="padding: 0px; margin: 5px;width: 100%;">
                            {{ baseGoods.goods_name }}-{{baseGoods.id}}
                        </Radio>
                    </RadioGroup>
                </div>
                <span v-else>{{ row.agent_goods.goods.goods_name}}</span>
            </template>
            <template slot-scope="{ row }" slot="company_name">
                {{ row.shop.company.company_name}}
            </template>
            <template slot-scope="{ row, index }" slot="shop_name">
                <div v-if="editIndex === index">
                    <RadioGroup v-model="row.shop_id">
                        <Radio v-for="(shop, ind) of shops" :key="ind" :label="shop.id" style="padding: 0px; margin: 5px;width: 100%;">
                            {{ shop.shop_name }}
                        </Radio>
                    </RadioGroup>
                </div>
                <span v-else>{{ row.shop.shop_name}}</span>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <div v-if="editIndex === index">
                    <Button @click="handleSave(row, index)" style="margin-right: 6px">保存</Button>
                    <Button @click="editIndex = -1">取消</Button>
                </div>
                <div v-else>
                    <Button type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                    <Button type="error" style="margin-right: 5px" @click="deleteModal=true">删除</Button>
                    <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete(row)">
                        <p>您确定要删除这个数据吗？</p>
                        <p>公司: {{ row.shop.company.company_name }}</p>
                        <p>店名: {{ row.shop.shop_name }}</p>
                        <p>编码: {{ row.shop.other_goods_no }}</p>
                    </Modal>
                </div>
            </template>
        </Table>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            deleteModal: false,
            createObject: {},
            createFormSwitch: false,
            editId: -1,
            shops: [],
            goodsList: [],	// 所有的直充和卡券产品，不包含权益包
            editObject: {},
            contacts: [],
            editIndex: -1,
            loading: false,
            agentGoods: [],
            error: null,
            companies: [],
            baseGoods: [],
            selectCompanyId: 0,
            agentGoodsSelectedIds: [],
            dis: false,
            columns1: [
                { type: "selection", width: 60, align: "center" },
                /* { title: "ID", key: "id", align: "center", width: 100 }, */
                {
                    title: "ID",
                    key: "id",
                    align: "center",
                    width: 100,
                },
                { title: "公司", slot: "company_name", align: "center", },
                { title: "店铺", slot: "shop_name", align: "left", },
                { title: "商品", slot: "goods_name", align: "left", width: 300 },
                /* { title: "来源", slot: "origin_name", align: "center", width: 120 }, */
                { title: "三方商品编码", slot: "other_goods_no", align: "center" },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                },
            ],
            ruleValidate: {
                shop_id: [
                    { required: true, message: "请选择店铺", },
                ],
                agent_goods_id: [
                    { required: true, message: "请选择商品" },
                ],
                other_goods_no: [
                    { required: true, message: "请填写第三方商品编码" },
                ],
            },
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        handleDelete (row) {
            this.loading = true;
            this.axios.get('agentGoods/delOtherNo/' + row.id).then((res) => {
                console.log("删除返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success("删除成功");
                    let index = -1;//这里不能设置0，因为数组索引是从0开始的
                    for (let i = 0; i < this.contacts.length; i++) {
                        if (this.contacts[i].id == row.id) {
                            index = i;
                            break
                        }
                    }
                    if (index >= 0) {
                        this.contacts.splice(index, 1);
                    }
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        /**
         * 新建数据提交表单
         */
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.axios.post("agentGoods/addOtherNo", this.createObject).then(res => {
                        if (res.data.status === 200) {
                            this.$Message.success("创建成功");
                            setTimeout(() => { this.$router.go(0) }, 2000);
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    })
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        companySelectChanged (companyId) {
            console.log("companySelectChanged companyId=>", companyId);
            this.axios.get('agentGoods/list?company_id=' + companyId).then(res => {
                this.goodsList = res.data.agent_goods;
                console.log('this.goodsList=>', this.goodsList);
            })
            this.axios.get('shops/list?company_id=' + companyId).then(res => {
                this.shops = res.data.shops;
            })
        },
        onSelectionChange (selectedELement) {
            console.log("selected=>", selectedELement);
            this.agentGoodsSelectedIds.length = 0;
            selectedELement.forEach((element) => {
                this.agentGoodsSelectedIds.push(element.id);
            });
        },
        handleEdit (row, index) {
            this.editObject = row;
            this.editIndex = index;
            this.editId = row.id;
            this.goodsList.length = 0;
            this.axios.get('agentGoods/list?company_id=' + row.agent_goods.company_id).then(res => {
                this.goodsList = this.goodsList.concat(res.data.agent_goods);
                console.log('this.goodsList=>', this.goodsList);
            })
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;
            this.loading = true;
            let data = {
                agent_goods_id: this.editObject.agent_goods_id,
                shop_id: this.editObject.shop_id,
                other_goods_no: this.editObject.other_goods_no,
            }
            this.axios.post("agentGoods/editOtherNo/" + this.editId, data).then((res) => {
                console.log("agentGoods/edit res=>", res);
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row.agent_sale_price = this.editAgentSalePrice;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        fetchData () {
            this.error = null;
            this.axios.get("companies").then((res) => {
                console.log("agentUsers 读取回来的数据=>", res.data);
                this.companies = res.data.companies;
            });
            this.axios.get("agentGoods/goodsContactList").then((res) => {
                this.contacts = res.data.agent_goods_contact;
            });
            /* this.axios.get('shops/list').then(res => {
                this.shops = res.data.shops;
            }) */
            this.axios.get('origin/list').then(res => {
                this.origins = res.data.origin;
            })
        },
    },
};
</script>
