<!--
 * @Author: 陈远锋
 * @Date: 2022-04-21 13:12:11
 * @LastEditTime: 2022-04-21 14:16:13
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\shop\index.vue
 * @Description: Do not edit
-->
<!--
 * @Author: 陈远锋
 * @Date: 2022-04-21 09:12:02
 * @LastEditTime: 2022-04-21 13:08:33
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\origin\index.vue
 * @Description: Do not edit
-->
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-04-02 10:12:09
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\goods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <Button style="float:right;" @click="createSwitch=true">
                新增店铺
            </Button>
            <BreadcrumbItem>店铺管理</BreadcrumbItem>
            <BreadcrumbItem>店铺列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>

        <Form ref="createObject" :model="createObject" :rules="ruleValidate" label-position="right" :label-width="200" v-if="createSwitch">
            <FormItem label="代理公司" prop="company_id" v-model="createObject.company_id">
                <RadioGroup v-model="createObject.company_id">
                    <Radio v-for="(company, ind) of companies" :key="ind" :label="company.id">
                        <span>{{ company.company_name }}</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="来源代码" prop="origin_code" v-model="createObject.origin_code">
                <RadioGroup v-model="createObject.origin_code">
                    <Radio v-for="(origin, ind) of origins" :key="ind" :label="origin.origin_code">
                        <span>{{origin.origin_name}}</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="店铺名字" prop="shop_name">
                <Input v-model="createObject.shop_name" placeholder="请输入店铺名字"></Input>
            </FormItem>
            <FormItem label="三方店铺ID" prop="other_id">
                <Input v-model="createObject.other_id" placeholder="请输入三方店铺ID"></Input>
            </FormItem>
            <FormItem label="app_key" prop="app_key">
                <Input v-model="createObject.app_key" placeholder="请输入app_key"></Input>
            </FormItem>
            <FormItem label="app_secret" prop="app_secret">
                <Input v-model="createObject.app_secret" placeholder="请输入app_secret"></Input>
            </FormItem>

            <FormItem label="状态" prop="status_id">
                <RadioGroup v-model="createObject.status_id">
                    <Radio :label="0">关闭</Radio>
                    <Radio :label="1">正常</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="formHandleSubmit('createObject')">提交</Button>
                <Button style="margin-left: 8px" @click="createSwitch=false">取消</Button>
            </FormItem>
        </Form>

        <Table border stripe :columns="columns1" :data="shops">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row, index }" slot="shop_name">
                <Input v-if="editIndex === index" type="text" v-model="editObject.shop_name" />
                <span v-else>{{ row.shop_name }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="origin_code">
                <div v-if="editIndex === index">
                    <RadioGroup v-model="editObject.origin_code">
                        <Radio v-for="(origin, ind) of origins" :key="ind" :label="origin.origin_code">
                            <span>{{origin.origin_name}}</span>
                        </Radio>
                    </RadioGroup>
                </div>
                <span v-else>{{ row.origin_code }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="other_id">
                <Input v-if="editIndex === index" type="text" v-model="editObject.other_id" />
                <span v-else>{{ row.other_id }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="app_key">
                <Input v-if="editIndex === index" type="text" v-model="editObject.app_key" />
                <span v-else>{{ row.app_key }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="app_secret">
                <Input v-if="editIndex === index" type="text" v-model="editObject.app_secret" />
                <span v-else>{{ row.app_secret }}</span>
            </template>
            <template slot-scope="{ row }" slot="status_desc">
                <i-switch :value="row.statusBool" size="large" @on-change="statusOnChange(row)">
                    <span slot="open">开启</span>
                    <span slot="close">关闭</span>
                </i-switch>
            </template>

            <template slot-scope="{ row, index }" slot="action">
                <div v-if="editIndex === index">
                    <Button @click="handleSave(row, index)" type="primary" style="margin-right: 6px">保存</Button>
                    <Button @click="editIndex = -1">取消</Button>
                </div>
                <div v-else>
                    <Button type="primary" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                    <Button type="error" style="margin-right: 5px" @click="deleteModal=true">删除</Button>
                    <Modal v-model="deleteModal" title="删除确认" @on-ok="handleDelete(row)">
                        <p>您确定要删除这个店铺吗？</p>
                        <p>店名: {{ row.shop_name }}</p>
                        <p>公司: {{ row.company.company_name }}</p>
                    </Modal>
                </div>
            </template>
        </Table>
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            deleteModal: false,
            createSwitch: false,
            createObject: {},
            loading: false,
            shops: [],
            error: null,
            editIndex: -1,
            editId: -1,
            editObject: { origin_name: '', origin_code: '' },
            columns1: [
                /* { align: "center", title: "店铺ID", key: "id", width: 80 }, */
                { align: "center", title: "公司", slot: "company_name" },
                { align: "center", title: "来源", width: 100, slot: "origin_code" },
                { align: "center", title: "第三方店铺ID", width: 150, slot: "other_id" },
                { align: "center", title: "名称", slot: "shop_name" },
                { align: "center", title: "app_key", slot: "app_key" },
                { align: "center", title: "app_secret", slot: "app_secret" },
                { align: "center", title: "状态", width: 100, slot: "status_desc" },
                { title: "操作", slot: "action", align: "center", },
            ],
            ruleValidate: {
                company_id: [
                    { required: true, message: "请选择代理公司", },
                ],
                origin_code: [
                    { required: true, message: "请选择来源", },
                ],
                shop_name: [
                    { required: true, message: "店铺名字不能为空", },
                ],
                origin_name: [
                    { required: true, message: "来源名称不能为空", },
                ],
                other_id: [
                    { required: true, message: "三方店铺ID不能为空", },
                ],
                app_key: [
                    { required: true, message: "app_key不能为空", },
                ],
                app_secret: [
                    { required: true, message: "app_secret不能为空", },
                ],
                status_id: [
                    { required: true, message: "请选择状态" },
                ],
            },
            origins: [],
            companies: [],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        formHandleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    this.axios.post("shops/create", this.createObject).then(res => {
                        if (res.data.status === 200) {
                            this.$Message.success("创建成功");
                            setTimeout(() => {
                                this.$router.go(0)
                            }, 2000);
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    })
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
        statusOnChange: function (shop) {
            console.log('shop=>', shop);
            let statusId = shop.statusBool ? 0 : 1;
            shop.statusBool = shop.statusBool ? false : true;
            let data = {
                id: shop.id,
                status_id: statusId
            }
            this.loading = true;
            this.axios.post('shops/setStatus', data).then((res) => {
                console.log("修改状态返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        switch1Change: function (e) {
            console.log('switch1 发生 change 事件，携带值为', e.detail.value)
        },
        handleEdit (row, index) {
            this.editId = row.id;
            this.editIndex = index;
            this.editObject = row;
        },
        handleDelete (row) {
            this.loading = true;
            this.axios.get('shops/del/' + row.id).then((res) => {
                console.log("删除返回res", res);
                this.loading = false;
                if (res.data.status === 204) {
                    this.$Message.success("删除成功");
                    let index = -1;//这里不能设置0，因为数组索引是从0开始的
                    for (let i = 0; i < this.shops.length; i++) {
                        if (this.shops[i].id == row.id) {
                            index = i;
                            break
                        }
                    }
                    if (index >= 0) {
                        this.shops.splice(index, 1);
                    }
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        handleSave (row) {
            this.editIndex = -1;
            this.loading = true;
            this.axios.post('shops/edit/' + row.id, this.editObject).then((res) => {
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row.origin_code = this.editObject.origin_code;
                    row.other_id = this.editObject.other_id;
                    row.shop_name = this.editObject.shop_name;
                    row.app_secret = this.editObject.app_secret;
                    row.app_key = this.editObject.app_key;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/goods_edit/" + row.id);
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("shops/list").then((res) => {
                console.log("res", res);
                res.data.shops.forEach(element => {
                    element.statusBool = element.status_id == 1 ? true : false;
                });
                this.shops = res.data.shops;
                this.loading = false;
            });
            this.axios.get('origin/list').then(res => {
                this.origins = res.data.origin;
            })
            this.axios.get('companies').then(res => {
                this.companies = res.data.companies;
            })
        },
    },
};
</script>
