<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 16:02:57
 * @LastEditTime: 2022-03-14 10:25:18
 * @FilePath: \qyOut\src\pages\member\batch_orders_expand.vue
 * @Description: Do not edit
-->
<template>
    <div class="expand-row">
        <Table border stripe :columns="columns2" :data="row"> </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Array
    },
    data () {
        return {
            tableData: [],
            columns2: [
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '订单号', key: 'order_no' }, {
                    align: "center",
                    title: '账号',
                    key: 'account'
                }, {
                    align: "center",
                    title: '价格',
                    key: 'price'
                }, {
                    align: "center",
                    title: '状态',
                    key: 'status_desc'
                }
            ]
        }
    }
};
</script>
<style scoped>
.expand-row {
    margin-bottom: 16px;
}
</style>
