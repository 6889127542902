<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 12:49:45
 * @LastEditTime: 2022-03-08 15:33:01
 * @FilePath: \qyOut\src\pages\agentGoods\form.vue
 * @Description: Do not edit
-->
<template>
    <Form ref="agentGoods" :model="agentGoods" :rules="ruleValidate" label-position="right" :label-width="200">
        <FormItem label="商品名称">
            <Input v-model="agentGoods.goods_name" placeholder="请输入商品名称" disabled></Input>
        </FormItem>
        <FormItem label="原价">
            <Input v-model="agentGoods.original_price" placeholder="请输入商品原价" disabled></Input>
        </FormItem>
        <FormItem label="商品售价" prop="sale_price">
            <Input v-model="agentGoods.sale_price" placeholder="请输入商品编号"></Input>
        </FormItem>

        <FormItem>
            <Button type="primary" @click="formHandleSubmit('agentGoods')">Submit</Button>
            <Button style="margin-left: 8px">Cancel</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    props: {
        agentGoods: Object,
    },
    data () {
        return {
            ruleValidate: {
                sale_price: [
                    {
                        required: true,
                        message: " sale_price 不能为空",
                        trigger: "blur",
                    },
                ],
                goods_no: [
                    {
                        required: true,
                        message: "code不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        formHandleSubmit: function (name) {
            console.log("name=>", name);
            this.$refs[name].validate((valid) => {
                if (valid) {
                    // 验证通过
                    console.log("验证通过");
                    this.loading = true;
                    this.error = null;
                    console.log("this.agentGoods=>", this.agentGoods);
                    this.$emit("handleSubmit", this.agentGoods);
                } else {
                    this.$Message.error("请输入正确的数据!");
                }
            });
        },
    },
};
</script>
