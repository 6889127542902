<!--
 * @Author: 陈远锋
 * @Date: 2022-04-02 10:33:05
 * @LastEditTime: 2022-04-02 11:10:26
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\companies\create.vue
 * @Description: Do not edit
-->
<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:52:40
 * @LastEditTime: 2022-03-14 13:33:16
 * @FilePath: \qyOut\src\pages\user\create.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>代理公司管理</BreadcrumbItem>
            <BreadcrumbItem>添加下级公司</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :company="company" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            company: {},
            loading: false,
            error: '',

        }
    },
    methods: {
        handleSubmit (goods) {
            // 验证通过
            console.log("handleSubmit 开始");
            this.loading = true; this.error = null;
            this.axios.post("companies", goods).then((res) => {
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 201) {
                    this.$Message.success('添加成功');
                    setTimeout(() => {
                        this.$router.push("/pages/companies");
                    }, 2000);
                } else {
                    console.log(res.data.errors);
                    for (let key in res.data.errors) {
                        console.log(res.data.errors[key]);
                        res.data.errors[key].forEach(err => {
                            console.log(err);
                            this.$Message.error(err);
                        })
                    }

                }
            }).catch(error => {
                console.log(error);
                this.error = error.msg;
            })
        }
    },
};
</script>
