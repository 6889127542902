<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 23:21:10
 * @LastEditTime: 2022-03-09 10:26:52
 * @FilePath: \qyOut\src\pages\agentGoods\edit.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>代理商商品管理</BreadcrumbItem>
            <BreadcrumbItem>编辑代理商商品</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <formPage :agentGoods="agentGoods" v-on:handleSubmit="handleSubmit"></formPage>
    </div>
</template>
<script>
import formPage from './form.vue';
import loading from '../loading.vue';
export default {
    components: { formPage, loading },
    data () {
        return {
            error: null,
            loading: false,
            agentGoods: {}
        }
    },
    created () {
        this.fetchData();
    },
    methods: {
        handleSubmit (goods) {
            console.log('enter handleSubmit');
            this.axios.post('agentGoods/edit/' + goods.id, goods).then(res => {
                console.log('agentGoods/edit res=>', res);
                console.log("修改返回res", res)
                this.loading = false;
                if (res.data.status === 200) {
                    this.$Message.success('修改成功');
                    setTimeout(() => {
                        this.$router.push("/pages/agent_goods_list");
                    }, 2000);
                }
            })
        },
        fetchData: function () {
            this.axios.get('agentGoods/detail/' + this.$route.params.id).then(res => {
                console.log('agentGoods/detail res=>', res);
                this.agentGoods = res.data.agent_goods;
                this.agentGoods.goods_name = this.agentGoods.goods.goods_name;
            })
        }
    }
}
</script>
