/*
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:59
 * @LastEditTime: 2022-05-20 23:39:44
 * @FilePath: /qyOut/src/routes.js
 * @Description: Do not edit
 */
// 1. 定义 (路由) 组件。
// 可以从其他文件 import 进来

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import app from './App.vue'
import pagesIndex from './pages/index.vue'
import pay from './Pay.vue'
import home from './Home.vue'
import yisq from './Yisq.vue'
import test from './test.vue'

import login from './Login.vue'


import goods_type_list from './pages/goods_type/list'
import goods_type_edit from './pages/goods_type/edit'
import goods_type_create from './pages/goods_type/create'

import goods_list from './pages/goods/list';
import goods_create from './pages/goods/create';
import goods_edit from './pages/goods/edit';
import goods_box_goods_edit from './pages/goods/box_goods_edit';

import supplier_list from './pages/supplier/list';
import supplier_create from './pages/supplier/create';
import supplier_edit from './pages/supplier/edit';

import supplierGoods_list from './pages/supplierGoods/list';
import supplierGoods_edit from './pages/supplierGoods/edit';

import agentGoods_list from './pages/agentGoods/list';
import agentGoodsContacts from './pages/agentGoods/contact';
import agentGoods_create from './pages/agentGoods/create';
import agentGoods_edit from './pages/agentGoods/edit';

import user_edit from './pages/user/edit';
import user_create from './pages/user/create';
import user_add_balance from './pages/user/add_balance';
import user_bills from './pages/user/bills';
import users from './pages/user/list';
import user_edit_roles from './pages/user/edit_roles';

import prepaid_orders from './pages/prepaid_orders/list';
import coupon_orders from './pages/coupon_orders/list';
import couponSendCode from './pages/coupon_orders/sendCode';
import couponShowCode from './pages/coupon_orders/showCode';

import couponAfterSales from './pages/coupon_orders/after_sales';

// 普通用户进来的路由
import member_place_order from './pages/member/place_order';
import member_place_coupon from './pages/member/place_coupon';
/* import member_orders from './pages/member/orders'; */
import member_goods from './pages/member/goods';
import member_batch_orders from './pages/member/batch_orders';
import member_bills from './pages/member/bills';
import member_info from './pages/member/info';
import member_coupons from './pages/member/coupons';

import roles from './pages/roles/index';
import roles_create from './pages/roles/create';
import roles_edit from './pages/roles/edit';
import roles_permission from './pages/roles/permission';

import permissions from './pages/permissions/index';
import permission_create from './pages/permissions/create';
import permission_edit from './pages/permissions/edit';

import companies from './pages/companies/index';
import companies_add_balance from './pages/companies/add_balance';
import companies_create from './pages/companies/create';
import companies_transfer_deposit from './pages/companies/transfer_deposit';


import origins from './pages/origin/index';
import shops from './pages/shop/index';
// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。1
const routes = [
	{ path: '/app', component: app },
	{ path: '/coupon/:code', component: couponShowCode, name: "展示石油券码", meta: { title: '浙江中石油优惠券' } },
	{
		path: '/pages', component: pagesIndex,
		children: [
			{ path: 'shops', component: shops },
			{ path: 'origins', component: origins },

			{ path: 'companies', component: companies },
			{ path: 'companies_add_balance/:id', component: companies_add_balance },
			{ path: 'companies_transfer_deposit/:id', component: companies_transfer_deposit },
			{ path: 'companies_create', component: companies_create },

			// 商品类型
			{ path: 'goods_type_list', component: goods_type_list },
			{ path: 'goods_type_edit/:id', component: goods_type_edit },
			{ path: 'goods_type_create', component: goods_type_create },
			// 基础商品
			{ path: 'goods_list', component: goods_list },
			{ path: 'goods_edit/:id', component: goods_edit },
			{ path: 'goods_create', component: goods_create },
			{ path: 'goods_box_goods_edit/:id', component: goods_box_goods_edit },
			// 供应商相关
			{ path: 'supplier_list', component: supplier_list },
			{ path: 'supplier_create', component: supplier_create },
			{ path: 'supplier_edit/:id', component: supplier_edit },
			// 供应商商品相关
			{ path: 'supplier_goods_list', component: supplierGoods_list },
			{ path: 'supplier_goods_edit/:id', component: supplierGoods_edit },
			// 代理商商商品相关
			{ path: 'agent_goods_list', component: agentGoods_list },
			{ path: 'agent_goods_contacts', component: agentGoodsContacts },
			{ path: 'agent_goods_create', component: agentGoods_create },
			{ path: 'agent_goods_edit/:id', component: agentGoods_edit },
			// 批量订单列表
			{ path: 'prepaid_orders', component: prepaid_orders },
			{ path: 'coupon_orders', component: coupon_orders },
			{ path: 'coupon_after_sales', component: couponAfterSales },
			{ path: 'show-code/:code', component: couponShowCode },
			{ path: 'coupon_send_code', component: couponSendCode },

			{ path: 'users', component: users },
			{ path: 'user_edit/:id', component: user_edit },
			{ path: 'user_create', component: user_create },
			{ path: 'user_add_balance/:id', component: user_add_balance },
			{ path: 'user_bills', component: user_bills },
			{ path: 'user_edit_roles/:id', component: user_edit_roles },

			// 普通用户，也就是代理商的页面
			{ path: 'member_place_order', component: member_place_order },
			{ path: 'member_place_coupon', component: member_place_coupon },
			/* { path: 'member_orders', component: member_orders }, */
			{ path: 'member_goods', component: member_goods },
			{ path: 'member_batch_orders', component: member_batch_orders },
			{ path: 'member_bills', component: member_bills },
			{ path: 'member_info', component: member_info },
			{ path: 'member_coupons', component: member_coupons },

			{ path: 'roles', component: roles },
			{ path: 'roles_edit/:id', component: roles_edit },
			{ path: 'roles_permission/:id', component: roles_permission },
			{ path: 'roles_create', component: roles_create },

			{ path: 'permissions', component: permissions },
			{ path: 'permission_edit/:id', component: permission_edit },
			{ path: 'permission_create', component: permission_create },
		]
	},
	{ path: '/pay', component: pay },
	{ path: '/home', component: home },
	{ path: '/yisq', component: yisq },
	{ path: '/test', component: test },
	{ path: '/login', name: '登陆', component: login, mate: { title: '请登陆' } },
]


// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
export default new VueRouter({
	mode: 'history',
	routes // (缩写) 相当于 routes: routes
})
