<!--
 * @Author: 陈远锋
 * @Date: 2022-03-06 17:53:11
 * @LastEditTime: 2022-04-01 14:42:44
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\goods.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>商品管理</BreadcrumbItem>
            <BreadcrumbItem>已授权商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Table border stripe :columns="columns1" :data="goods">
            <template slot-scope="{ row }" slot="name">
                <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            error: null,
            goods: [],
            columns1: [
                /* { title: 'ID', key: 'id', align: "center" }, */
                { title: '产品名称', key: 'goods_name', align: "center" },
                { title: '产品编号', key: 'goods_no', align: "center" },
                { title: '原价', key: 'original_price', align: "center" },
                { title: '进价', key: 'sale_price', align: "center" },
                { title: '上/下架', key: 'sale_desc', align: "center" },
                { title: '状态', key: 'status_desc', align: "center" },
            ]
        }

    },
    created () {
        this.fetchData();
    },
    methods: {
        fetchData: function () {
            this.axios.get('agentGoods/list').then(res => {
                console.log('agentGoods/list res=>', res);
                this.goods = res.data.agent_goods;
                /* this.goods.forEach(element => {
                    element.goods_name = element.goods.goods_name;
                    element.goods_no = element.goods.goods_no;
                }); */
            })
        }
    }
}
</script>
