var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{style:({ margin: '20px 10px' })},[_c('router-link',{staticStyle:{"float":"right"},attrs:{"to":"/pages/companies_create"}},[_vm._v(" 新增公司 ")]),_c('BreadcrumbItem',[_vm._v("代理商公司管理")]),_c('BreadcrumbItem',[_vm._v("公司列表")])],1),_c('loading',{attrs:{"loading":_vm.loading,"error":_vm.error}}),(_vm.companies)?_c('div',{staticClass:"content"},[_c('Table',{attrs:{"border":"","stripe":"","columns":_vm.columns1,"data":_vm.companies},scopedSlots:_vm._u([{key:"company_name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.company_name),callback:function ($$v) {_vm.$set(_vm.editCompany, "company_name", $$v)},expression:"editCompany.company_name"}}):_c('span',[_vm._v(_vm._s(row.company_name))])]}},{key:"short_name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.short_name),callback:function ($$v) {_vm.$set(_vm.editCompany, "short_name", $$v)},expression:"editCompany.short_name"}}):_c('span',[_vm._v(_vm._s(row.short_name))])]}},{key:"contact",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.contact),callback:function ($$v) {_vm.$set(_vm.editCompany, "contact", $$v)},expression:"editCompany.contact"}}):_c('span',[_vm._v(_vm._s(row.contact))])]}},{key:"phone",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.phone),callback:function ($$v) {_vm.$set(_vm.editCompany, "phone", $$v)},expression:"editCompany.phone"}}):_c('span',[_vm._v(_vm._s(row.phone))])]}},{key:"facility",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.userInfo.isSuperAdmin)?_c('div',[(_vm.editIndex === index)?_c('Input',{attrs:{"type":"text"},model:{value:(_vm.editCompany.facility),callback:function ($$v) {_vm.$set(_vm.editCompany, "facility", $$v)},expression:"editCompany.facility"}}):_c('span',[_vm._v(_vm._s(row.facility))])],1):_c('div',[_c('span',[_vm._v(_vm._s(row.facility))])])]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(_vm.editIndex === index)?_c('div',[_c('Button',{staticStyle:{"margin-right":"6px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSave(row, index)}}},[_vm._v("保存")]),_c('Button',{on:{"click":function($event){_vm.editIndex = -1}}},[_vm._v("取消")])],1):_c('div',[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.handleEdit(row, index)}}},[_vm._v("修改")]),(_vm.userInfo.company_id != row.id)?_c('router-link',{attrs:{"to":'/pages/companies_transfer_deposit/'+row.id}},[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"}},[_vm._v("转账")])],1):_vm._e(),(_vm.userInfo.isSuperAdmin)?_c('router-link',{attrs:{"to":'/pages/companies_add_balance/'+row.id}},[_c('Button',{staticStyle:{"margin":"5px"},attrs:{"type":"primary","size":"small"}},[_vm._v("超级管理直接加款")])],1):_vm._e()],1)]}}],null,false,3889246014)})],1):_vm._e(),_c('expandRow',{staticStyle:{"display":"none"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }