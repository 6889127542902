<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:41:52
 * @LastEditTime: 2022-05-20 15:07:42
 * @FilePath: \qyOut\src\pages\coupon_orders\after_sales.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>券码管理</BreadcrumbItem>
            <BreadcrumbItem>抖音售后</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="date">
                <Select v-model="selectForm.company_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
                    <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.short_name }}</Option>
                </Select>
            </FormItem>
            <FormItem prop="date">
                <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
            </FormItem>
            <FormItem prop="date">
                <Input v-model="selectForm.aftersale_id" placeholder="退款单号" />
            </FormItem>
            <FormItem>
                <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
            </FormItem>
        </Form>
        <Table border stripe :columns="columns1" :data="orders">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button v-if="row.aftersale_status == 6" type="primary" size="small" style="margin: 3px" @click="agreeRefund(row, 1)">同意退款</Button><br />
                <Button v-if="row.aftersale_status == 6" type="error" size="small" style="margin: 3px" @click="agreeRefund(row, 0)">拒绝退款</Button>
            </template>
        </Table>

        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            error: null,
            orders: [],
            pages: {},
            selectForm: {},
            companies: [],
            ruleInline: {},
            columns1: [
                { align: "center", title: '退款申请时间', key: 'apply_time' },
                { align: "center", title: '产品', key: 'goods_name' },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '店铺名', key: 'shop_name' },
                { align: "center", title: '退款单号', key: 'aftersale_id' },
                { align: "center", title: '退款理由', key: 'reason_desc' },
                { align: "center", title: '退款金额', key: 'refund_amount', width: 100 },
                { align: "center", title: '退款类型', key: 'aftersale_type_desc', width: 150 },
                { align: "center", title: '状态', key: 'aftersale_status_desc', width: 120 },
                { align: "center", title: '操作', slot: 'action', width: 130 }
            ]
        }
    },
    created () {
        this.fetchData('refundOrders/list');
        this.axios.get('companies').then(res => {
            this.companies = res.data.companies
        })
    },
    methods: {
        agreeRefund (order, status) {
            this.axios.post('refundOrders/refund/' + order.id, { status: status }).then(res => {
                console.log('refundOrders/refund', res);
                if (res.data.status == 200) {
                    this.$Message.success('操作成功');
                } else {
                    this.$Message.error(res.data.msg);
                }
                setTimeout(() => { this.$router.go(0); }, 3000);
            })
        },
        dateOnChange (date) {
            console.log(date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
        },
        handleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.fetchData('refundOrders/list', this.selectForm);
                } else {
                    this.$Message.error('出错了');
                }
            })
        },
        pageOnChange: function (page) {
            console.log('page=>', page);
            let url = 'couponsOrders/list?page=' + page;
            this.fetchData(url, this.selectForm);
        },
        fetchData: function (url, param) {
            let data = {};
            for (let key in param) {
                console.log('param[key],', param[key]);
                if (param[key] === undefined || param[key].length == 0) {
                    delete param[key];
                }
            }
            data.params = param;
            this.loading = true;
            this.axios.get(url, data).then(res => {
                console.log('refundOrders/list', res);
                this.orders = res.data.refund_orders;
                this.pages = res.data.pages;
                this.loading = false;
            })
        }
    }
}
</script>
