<!--
 * @Author: 陈远锋
 * @Date: 2022-02-14 17:00:12
 * @LastEditTime: 2022-05-20 23:41:43
 * @FilePath: /qyOut/src/App.vue
 * @Description: Do not edit
-->
<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "token",
    ]),
    created: function () {
        // `this` 指向 vm 实例
        console.log("---------created========");
        console.log("isLogin=>", Boolean(this.token));
        console.log("token=>", this.token);
        console.log('this.$router.currentRoute.path=>', this.$router.currentRoute.path)
        if (this.token == false && this.$router.currentRoute.path.indexOf("/coupon/") == -1) {
            console.log("没有登陆过，跳去登陆页面");
            this.$router.push("/login");
        } else {
            axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
        }
    },
    mounted: function () {
        //document.title = "光凝权益平台";
        console.log("--------mounted========");
        console.log("isLogin=>", Boolean(this.token));
        console.log('this.$router.currentRoute.path=>', this.$router.currentRoute.path);
        if (this.token == false && this.$router.currentRoute.path.indexOf("/coupon/") == -1) {
            console.log("没有登陆过，跳去登陆页面");
            this.$router.push("/login");
        } else {
            axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
            console.log('path', this.$router.currentRoute.path);
            if (this.$router.currentRoute.path == '/') {
                this.$router.push("/pages/member_info");
            }
        }
    },
};
</script>
