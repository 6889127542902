<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-04-08 13:52:30
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\roles\index.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/roles_create" style="float:right;">
                新增角色
            </router-link>
            <BreadcrumbItem>角色管理</BreadcrumbItem>
            <BreadcrumbItem>角色列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <div v-if="roles" class="content">
            <Table border stripe :columns="columns1" :data="roles">
                <template slot-scope="{ row, index }" slot="name">
                    <Input v-if="editIndex === index" type="text" v-model="editName" />
                    <span v-else>{{ row.name }}</span>
                </template>
                <template slot-scope="{ row, index }" slot="ability">
                    <Input v-if="editIndex === index" type="text" v-model="editAbility" />
                    <span v-else>{{ row.ability }}</span>
                </template>
                <template slot-scope="{ row }" slot="permissions">
                    <Row type="flex" justify="space-around" class="code-row-bg" style="margin: 5px;">
                        <Col span="6" v-for="(permission, ind)  of row.permissions" :key="ind" style="margin: 5px;"> {{permission.name}}</Col>
                    </Row>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                    <div v-if="editIndex === index">
                        <Button @click="handleSave(row, index)" type="primary" style="margin-right: 6px">保存</Button>
                        <Button @click="editIndex = -1">取消</Button>
                    </div>
                    <div v-else>
                        <Button type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                        <router-link :to="'/pages/roles_permission/'+row.id">
                            <Button type="primary" size="small" style="margin-right: 5px">设置权限</Button>
                        </router-link>
                    </div>
                </template>
            </Table>
        </div>
        <expandRow style="display: none"></expandRow>
    </div>
</template>
<script>

import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            roles: null,
            error: null,
            columns1: [
                /* { title: "时间", key: "updated_at", align: "center", }, */
                /* { align: "center", title: "ID", key: "id", width: 80 }, */
                { align: "center", title: "守卫名字", key: "guard_name", width: 100 },
                { align: "center", title: "名字", key: "name", slot: "name" },
                { align: "center", title: "能力", key: "ability", slot: "ability" },
                { align: "center", title: "权限", slot: "permissions", width: 500 },
                { title: "操作", slot: "action", width: 200, align: "center", },
            ],
            editIndex: -1,
            editName: "",
            editAbility: "",
            editId: -1,
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        handleEdit (row, index) {
            this.editId = row.id;
            this.editIndex = index;
            this.editName = row.name;
            this.editGuardName = row.guardName;
            this.editAbility = row.ability;
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;
            let data = {
                name: this.editName,
                //guard_name: this.editGuardName,
                ability: this.editAbility,
            };

            this.loading = true;
            this.axios.patch('roles/' + this.editId, data).then((res) => {
                console.log("role/edit res=>", res);
                console.log("修改返回res", res);
                this.loading = false;
                if (res.data.status === 200) {
                    row.name = this.editName;
                    row.ability = this.editAbility;
                    this.$Message.success("修改成功");
                } else {
                    this.$Message.error(res.data.msg);
                }
            });
        },
        fetchData () {
            this.error = this.post = null;
            this.loading = true;
            this.axios.get("roles").then((res) => {
                console.log("res", res);
                this.roles = res.data.roles;
                this.loading = false;
                this.roles.forEach(element => {
                    element._expanded = false;
                });
            });
        },
    },
};
</script>
