<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:41:52
 * @LastEditTime: 2022-04-02 10:01:04
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\coupon_orders\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>券码管理</BreadcrumbItem>
            <BreadcrumbItem>券码订单列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="selectForm" :model="selectForm" inline>
            <FormItem prop="date">
                <Select v-model="selectForm.company_id" style="width: 250px; margin-right: 10px; margin-left: 10px;">
                    <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.short_name }}</Option>
                </Select>
            </FormItem>
            <FormItem prop="date">
                <DatePicker type="daterange" placeholder="时间范围" @on-change="dateOnChange"></DatePicker>
            </FormItem>

            <FormItem>
                <Button type="primary" @click="handleSubmit('selectForm')">查询</Button>
            </FormItem>
        </Form>
        <Table border stripe :columns="columns1" :data="orders">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
        <expandRow style="display: none"></expandRow>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import expandRow from '../member/coupon_expand.vue';
import loading from '../loading.vue';
export default {
    components: { expandRow, loading },
    data () {
        return {
            loading: false,
            error: null,
            orders: [],
            pages: {},
            selectForm: {},
            companies: [],
            ruleInline: {},
            columns1: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        let order = params.row.coupons_code;
                        return h(expandRow, {
                            props: {
                                row: order
                            }
                        })
                    }
                },
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '代理公司', slot: 'company_name' },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '数量', key: 'num', width: 100 },
                { align: "center", title: '单价', key: 'unit_price', width: 100 },
                { align: "center", title: '金额', key: 'total', width: 100 },
                { align: "center", title: '状态', key: 'status_desc', width: 100 }
            ]
        }
    },
    created () {
        this.fetchData('couponsOrders/list');
        this.axios.get('companies').then(res => {
            this.companies = res.data.companies
        })
    },
    methods: {
        dateOnChange (date) {
            console.log(date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
        },
        handleSubmit: function (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.fetchData('couponsOrders/list', this.selectForm);
                } else {
                    this.$Message.error('出错了');
                }
            })
        },
        pageOnChange: function (page) {
            console.log('page=>', page);
            let url = 'couponsOrders/list?page=' + page;
            this.fetchData(url, this.selectForm);
        },
        fetchData: function (url, param) {
            let data = {};
            for (let key in param) {
                console.log('param[key],', param[key]);
                if (param[key] === undefined || param[key].length == 0) {
                    delete param[key];
                }
            }
            data.params = param;
            this.loading = true;
            this.axios.get(url, data).then(res => {
                console.log('couponsOrders/list res', res);
                this.orders = res.data.coupons_order;
                this.orders.forEach(element => {
                    element._expanded = false;
                });
                this.pages = res.data.pages;
                this.loading = false;
            })
        }
    }
}
</script>
