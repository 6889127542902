<!--
 * @Author: 陈远锋
 * @Date: 2022-02-15 14:56:47
 * @LastEditTime: 2022-04-08 10:21:33
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\index.vue
 * @Description: Do not edit
-->
<template>
    <div class="layout">
        <Layout>
            <Header>
                <Menu mode="horizontal" theme="dark">
                    <div class="layout-logo" style="color: white; padding: 0; margin: 0">
                        <p style="left: 22px;height: 20rpx; top: -15px;position: relative;">
                            权益充值平台
                        </p>
                    </div>
                    <div class="layout-nav">
                        <MenuItem name="t-1">
                        <router-link to="/">
                            <Icon type="logo-yen" /> {{ companyInfo.balance }}
                        </router-link>
                        </MenuItem>
                        <MenuItem name="t-2">
                        <div v-on:click="logOut">
                            <Icon type="ios-walk"></Icon>
                            退出
                        </div>
                        </MenuItem>
                    </div>
                </Menu>
            </Header>
            <Layout>
                <Sider hide-trigger :style="{ background: '#fff' }">
                    <Menu active-name="0-1" theme="light" width="auto" :open-names="['submenu_0']" class="menuitemClasses">
                        <router-link v-for="(item, ind) of submenus" :key="ind" :to="item.url">
                            <MenuItem :name="ind" v-if="item.level==0 && item.url.length > 1">
                            <!-- <Icon :type="item.icon"></Icon> -->
                            <span>{{ item.name }}</span>
                            </MenuItem>
                        </router-link>
                    </Menu>
                </Sider>
                <Layout :style="{ padding: '12px 12px 12px' }">
                    <Content :style="{ minHeight: '290px', background: '#fff' }">
                        <router-view></router-view>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
        <Layout style="margin:6px; text-align: center;">
            <p> © 2020-2024 {{ companyInfo.company_name }} 版本号 1.0.20220408</p>
        </Layout>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    data () {
        return {
            loading: false,
            post: null,
            error: null,
            agentSubmenus: [
                { name: "批量直充", url: "/pages/member_place_order", icon: 'ios-baseball' },
                { name: "提取券码", url: "/pages/member_place_coupon", icon: 'ios-analytics' },
                { name: "直充订单列表", url: "/pages/member_batch_orders", icon: 'ios-american-football' },
                { name: "券码列表", url: "/pages/member_coupons", icon: 'md-football' },
                /* { name: "订单列表", url: "/pages/member_orders" }, */
                { name: "已授权商品", url: "/pages/member_goods", icon: 'ios-basketball' },
                { name: "资金变动记录", url: "/pages/member_bills", icon: 'md-football' },
                { name: "我的资料", url: "/pages/member_info", icon: 'ios-analytics' },
            ],
            submenus: [],
            adminSubmenus: [
                {
                    name: "直充订单管理",
                    url: '/pages/prepaid_orders',
                    icon: 'ios-baseball'
                }, {
                    name: "券码订单管理",
                    url: '/pages/coupon_orders',
                    icon: 'ios-baseball'
                }, {
                    name: "商品类型列表",
                    url: "/pages/goods_type_list",
                    icon: 'ios-american-football'
                },
                {
                    name: "基础商品列表",
                    url: "/pages/goods_list",
                    icon: 'ios-analytics'
                },
                {
                    name: "供应商",
                    url: "/pages/supplier_list",
                    icon: 'md-aperture'
                },
                {
                    name: "供应商商品列表",
                    url: "/pages/supplier_goods_list",
                    icon: 'md-analytics'
                },
                {
                    name: "代理商商品列表",
                    url: "/pages/agent_goods_list",
                    icon: 'ios-appstore'
                }, {
                    name: "用户列表",
                    url: "/pages/users",
                    icon: 'ios-basketball'
                }, {
                    name: "用户资金变化表",
                    url: "/pages/user_bills",
                    icon: 'md-football'
                },
            ],
        };
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo", 'balance', 'companyInfo'
    ]),
    created () {
        let menu = JSON.parse(localStorage.getItem('menu'));
        this.submenus = menu;
        console.log('submenus=>', this.submenus);
    },
    methods: {
        logOut: function () {
            console.log('准备退出');
            this.$store.commit("LOGOUT");
            this.$router.push('/login');
        }
    }
};
</script>
<style scoped>
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.layout-logo {
    width: 200px;
    height: 30px;
    font-size: 24px;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}

.layout-nav {
    width: 420px;
    margin: 0 auto;
    margin-right: 20px;
}

.layout-header-bar {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-logo-left {
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
}

.menu-icon {
    transition: all 0.3s;
}
.rotate-icon {
    transform: rotate(-90deg);
}
.menu-item span {
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width 0.2s ease 0.2s;
}
.menu-item i {
    transform: translateX(0px);
    transition: font-size 0.2s ease, transform 0.2s ease;
    vertical-align: middle;
    font-size: 16px;
}
.collapsed-menu span {
    width: 0px;
    transition: width 0.2s ease;
}
.collapsed-menu i {
    transform: translateX(5px);
    transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    vertical-align: middle;
    font-size: 22px;
}
</style>
