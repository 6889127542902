<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:41:18
 * @FilePath: \qyOut\src\pages\agentGoods\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/agent_goods_contacts" style="float:right;">
                三方商品绑定列表
            </router-link>
            <BreadcrumbItem>代理商商品管理</BreadcrumbItem>
            <BreadcrumbItem>代理商商品列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Select v-model="selectCompanyId" @on-change="userOnChange" style="margin: 10px; width: 250px;">
            <Option v-for="company in companies" :value="company.id" :key="company.id">{{ company.short_name }}</Option>
        </Select>
        <Table border stripe ref="selection" :columns="columns1" :data="baseGoods" v-if="selectCompanyId" @on-selection-change="onSelectionChange">
            <template slot-scope="{ row }" slot="name">
                <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="agent_sale_price">
                <Input type="text" v-model="editAgentSalePrice" v-if="editIndex === index" />
                <span v-else>{{ row.agent_sale_price }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <div v-if="editIndex === index">
                    <Button @click="handleSave(row, index)" style="margin-right: 6px">保存</Button>
                    <Button @click="editIndex = -1">取消</Button>
                </div>
                <div v-else>
                    <Button type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row, index)">修改</Button>
                </div>
            </template>
        </Table>
        <Row>
            <Button style="margin: 10px" @click="handleSelectAll(true)">全选</Button>
            <Button ref="buttonSubmitSave" style="margin: 10px" type="primary" :disabled="dis" @click="handleSubmitSave">提交</Button>
        </Row>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            editIndex: -1,
            editAgentSalePrice: "",
            editAgentGoodsId: -1,
            loading: false,
            agentGoods: [],
            error: null,
            companies: [],
            baseGoods: [],
            selectCompanyId: 0,
            agentGoodsSelectedIds: [],
            dis: false,
            columns1: [
                { type: "selection", width: 60, align: "center" },
                /* { title: "ID", key: "id", align: "center", width: 100 }, */
                {
                    title: "分类ID",
                    key: "goods_type_id",
                    align: "center",
                    width: 100,
                },
                { title: "商品名称", key: "goods_name", align: "center" },
                { title: "编号", key: "goods_no", align: "center", },
                {
                    title: "面额",
                    key: "original_price",
                    align: "center",
                },
                {
                    title: "售价",
                    key: "agent_sale_price",
                    align: "center",
                    slot: "agent_sale_price",
                },
                {
                    title: "上/下架",
                    key: "agent_sale_desc",
                    align: "center",
                },
                {
                    title: "状态",
                    key: "agent_status_desc",
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        onSelectionChange (selectedELement) {
            console.log("selected=>", selectedELement);
            this.agentGoodsSelectedIds.length = 0;
            selectedELement.forEach((element) => {
                this.agentGoodsSelectedIds.push(element.id);
            });
        },
        handleSelectAll (status) {
            this.$refs.selection.selectAll(status);
        },
        handleSubmitSave () {
            this.dis = true;
            setTimeout(() => { this.dis = false; }, 5000);
            if (this.selectCompanyId == 0) {
                this.$Message.error("出错了，您没有选定代理商用户");
                return;
            }
            if (this.agentGoodsSelectedIds.length == 0) {
                this.$Message.error(
                    "出错了，您没有改变过选择，或者没有选择商品"
                );
                return;
            }
            this.baseGoods.forEach((element) => {
                console.log("element=>", element);
            });
            let data = {
                company_id: this.selectCompanyId,
                goodsId: this.agentGoodsSelectedIds,
            };
            this.axios
                .post("agentGoods/batchUpdate", data)
                .then((res) => {
                    if (res.data.status == 200 || res.data.status == 204) {
                        this.$Message.success("修改成功");
                        setTimeout(() => {
                            this.$router.go(0);
                        }, 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.error = error.message;
                });
        },
        userOnChange: function (company_id) {
            this.error = null;
            this.loading = true;
            console.log("company_id=>", company_id);
            // 处理基础商品表
            this.baseGoods.forEach((goods_) => {
                goods_._checked = false;
                goods_.agent_sale_price = -1;
                goods_.agent_sale_desc = null;
                goods_.agent_status_desc = null;
                goods_.agent_goods_id = -1;
            });
            this.axios.get("agentGoods/list?company_id=" + company_id).then((res) => {
                console.log("agentGoods 读取回来的数据=>", res.data);
                this.agentGoods = res.data.agent_goods;
                let ids = [];
                this.agentGoods.forEach((goods_) => {
                    ids.push(goods_.goods_id);
                });
                console.log("ids=>", ids);
                this.baseGoods.forEach((baseGoods_) => {
                    console.log("id=>", baseGoods_.id);
                    if (ids.includes(String(baseGoods_.id))) {
                        baseGoods_._checked = true;
                    }
                    this.agentGoods.forEach((goods_) => {
                        if (goods_.goods_id == baseGoods_.id) {
                            baseGoods_.agent_sale_price = goods_.sale_price;
                            baseGoods_.agent_sale_desc = goods_.sale_desc;
                            baseGoods_.agent_status_desc = goods_.status_desc;
                            baseGoods_.agent_goods_id = goods_.id;
                        }
                    });
                });
                this.loading = false;
            });
        },
        handleEdit (row, index) {
            console.log("row=>", row.agent_goods_id);
            console.log("index=>", index);

            this.editAgentSalePrice = row.agent_sale_price;
            this.editAgentGoodsId = row.agent_goods_id;
            this.editIndex = index;
        },
        handleSave (row, index) {
            console.log("enter row", row);
            console.log("enter index", index);
            this.editIndex = -1;
            let data = {
                sale_price: this.editAgentSalePrice,
                inventory: "",
                advance_inventory: "",
            };

            this.loading = true;
            this.axios.patch("agentGoods/" + this.editAgentGoodsId, data)
                .then((res) => {
                    console.log("agentGoods/edit res=>", res);
                    console.log("修改返回res", res);
                    this.loading = false;
                    if (res.data.status === 200) {
                        row.agent_sale_price = this.editAgentSalePrice;
                        this.$Message.success("修改成功");
                    } else {
                        this.$Message.error(res.data.msg);
                    }
                });
        },
        fetchData () {
            this.error = null;
            this.loading = true;
            this.axios.get("companies").then((res) => {
                console.log("agentUsers 读取回来的数据=>", res.data);
                this.companies = res.data.companies;
                this.companies.push({
                    id: 0,
                    name: "请选择代理商",
                });
                this.loading = false;
            }).catch((error) => {
                console.log("error=>", error);
                console.log("error=>", error.response);
                console.log("error=>", error.message);
                this.error = error;
                this.loading = false;
            });
            this.axios.get("goods").then((res) => {
                res.data.goods.forEach((element) => {
                    element._checked = false;
                    element.agent_sale_price = -1;
                    element.agent_sale_desc = null;
                    element.agent_status_desc = null;
                    element.agent_goods_id = -1;
                });
                this.baseGoods = res.data.goods;
            });
        },
    },
};
</script>
