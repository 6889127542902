<!--
 * @Author: 陈远锋
 * @Date: 2022-03-05 08:47:12
 * @LastEditTime: 2022-06-27 14:18:44
 * @FilePath: \qyOut\src\pages\user\list.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <router-link to="/pages/user_create" style="float:right;">
                新建用户
            </router-link>
            <BreadcrumbItem>用户管理</BreadcrumbItem>
            <BreadcrumbItem>用户列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Table border stripe v-if="users" :columns="columns1" :data="users">
            <template slot-scope="{ row }" slot="company_name">
                {{ row.company.company_name }}
            </template>
            <template slot-scope="{ row }" slot="company_short_name">
                {{ row.company.short_name }}
            </template>
            <template slot-scope="{ row }" slot="roles">
                <List border style="margin: 3px;">
                    <ListItem v-for="(role, ind)  of row.roles" :key="ind"> {{role.name}} </ListItem>
                </List>

            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
                <!-- <Button type="primary" size="small" style="margin-right: 5px" @click="goAddBalance(row, index)">加款</Button> -->
                <router-link type="primary" :to="'/pages/user_edit_roles/'+row.id">
                    <Button type="primary" size="small" style="margin-right: 5px">设置权限</Button>
                </router-link>
            </template>
        </Table>
    </div>
</template>
<script>
import loading from "../loading.vue";
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            users: null,
            error: null,
            columns1: [
                /* { title: "时间", key: "updated_at", align: "center" }, */
                { title: "账号", key: "account", align: "center" },
                { title: "联系人", key: "name", align: "center" },
                { title: "手机", key: "phone", align: "center" },
                { title: "公司名称", align: "center", slot: "company_name" },
                { title: "公司简称", align: "center", slot: "company_short_name" },
                { title: "角色", align: "center", slot: "roles" },
                { title: "状态", key: "status_desc", align: "center", width: 80 },
                {
                    title: "操作",
                    slot: "action",
                    width: 250,
                    align: "center",
                },
            ],
        };
    },
    created () {
        console.log("mounted");
        this.fetchData();
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        $route: "fetchData",
    },
    methods: {
        goAddBalance (row) {
            this.$router.push("/pages/user_add_balance/" + row.id);
        },
        show (row, index) {
            console.log("row=>", row.id);
            console.log("index=>", index);
            this.$router.push("/pages/user_edit/" + row.id);
        },
        fetchData () {
            this.error = null;
            this.loading = true;
            this.axios.get("users").then((res) => {
                console.log("res", res);
                this.users = res.data.users;
                this.loading = false;
            });
        },
    },
};
</script>
