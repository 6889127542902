<!--
 * @Author: 陈远锋
 * @Date: 2022-03-11 13:13:35
 * @LastEditTime: 2022-05-07 14:22:10
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\pages\member\place_coupon.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>优惠券管理</BreadcrumbItem>
            <BreadcrumbItem>提取优惠券</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <Form ref="formItem" :model="formItem" :rules="ruleValidate" label-position="right" :label-width="200">
            <FormItem label="产品" prop="agent_goods_id">
                <Select v-model="formItem.agent_goods_id" @on-change="goodsSelected" :label-in-value="true">
                    <Option v-for="item in goods" :value="item.id" :key="item.id">{{ item.goods_name }} --面额 [{{
                            item.original_price
                        }}] ---售价[{{ item.sale_price }}]
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="数量" prop="num">
                <Row>
                    <Col>
                    <RadioGroup v-model.number="formItem.num">
                        <Radio v-for="(item, index) of [1,5,10]" :key="index" :label="item">
                            <span>{{ item }} 张</span>
                        </Radio>
                    </RadioGroup>
                    </Col>
                    <Col span="6">
                    <Input v-model.number="formItem.num" type="text" placeholder="请输入或选择券码张数"></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem>
                <Button ref="button" type="primary" @click="showModal1">提交</Button>
            </FormItem>
        </Form>

        <h1 style="text-align: center;">最近的订单</h1>
        <Table border stripe :columns="ordersColumns" :data="orders">
            <template slot-scope="{ row }" slot="name">
                <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
        <Modal v-model="modal1" title="请您确认订单信息" @on-ok="formHandleSubmit" @on-cancel="modal1 = false">
            <p>产品: {{ selectedGoods.label }}</p>
            <p></p>
            <p>张数: {{ formItem.num }}</p>
        </Modal>
    </div>
</template>
<script>
import loading from '../loading.vue';
import expandRow from './coupon_expand.vue';
import { mapState } from "vuex";
export default {
    components: { loading },
    data () {
        return {
            modal1: false,
            loading: false,
            error: null,
            goods: [],
            formItem: {},
            ordersColumns: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row.coupons_code
                            }
                        })
                    }
                },
                { align: "center", title: '时间', key: 'created_at' },
                { align: "center", title: '代理商', key: 'companyshort' },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '数量', key: 'num', width: 100 },
                { align: "center", title: '单价', key: 'unit_price', width: 100 },
                { align: "center", title: '金额', key: 'total', width: 100 },
                { align: "center", title: '状态', key: 'status_desc', width: 100 }
            ],
            formNum: 0,
            orders: [],
            ruleValidate: {
                agent_goods_id: [
                    { type: "integer", required: true, message: "请选择要要充值的产品", },
                ],
                num: [
                    { type: "integer", required: true, min: 1, max: 50, message: "请选择数量" },
                ],
            },
            butDisabled: false,
            selectedGoods: {}
        }
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "userInfo"
    ]),
    created () {
        this.fetchData();
    },
    methods: {
        showModal1 () {
            this.$refs['formItem'].validate((valid) => {
                if (valid) {
                    console.log(this.formItem.num);

                    this.modal1 = true;
                } else {
                    console.log('验证没有通过');
                    this.$Message.error('请正确填写表单！')
                }
            })
        },
        goodsSelected (item) {
            console.log('item=>', item);
            this.selectedGoods = item;
        },
        formHandleSubmit () {
            this.butDisabled = true;
            setTimeout(() => { this.butDisabled = false; }, 3000);
            this.axios.post('couponsOrders/drawCode', this.formItem).then(res => {
                console.log('res', res);
                if (res.data.status == 200) {
                    this.loading = false;
                    this.$Message.success('提券成功');
                    this.axios.get("userInfo").then((res) => {
                        console.log("login res", res);
                        if (res.data.status === 200) {
                            console.log("ok");
                            this.$store.commit("UPDATE_USERINFO", res.data.user);
                            this.$store.commit("UPDATE_BALANCE", res.data.user.company.balance);
                            this.$store.commit("UPDATE_VERSION", res.data.user.company.version);
                            this.$store.commit("UPDATE_COMPANY_INFO", res.data.user.company);
                        }
                    })
                    setTimeout(() => {
                        this.$router.push("/pages/member_coupons");
                    }, 2000);
                } else if (res.data.status == 1008) {
                    this.$Message.error("您余额不足!!");
                } else {
                    this.$Message.error("下单出错了");
                }
            }).catch(error => {
                console.error('error', error.message);
            })
        },
        /**
         * 获取提货券的产品列表
         */
        fetchData: function () {
            this.axios.get('agentGoods/list?type=1' + "&company_id=" + this.userInfo.company_id).then(res => {
                console.log('agentGoods/list res=>', res);
                this.goods = res.data.agent_goods;
                //this.goods.forEach(element => {
                //element.goods_name = element.goods.goods_name;
                //element.goods_no = element.goods.goods_no;
                //});
            })
            this.axios.get('couponsOrders/list').then(res => {
                console.log('order/list res', res);
                this.orders = res.data.coupons_order;
            })
        }
    }
}

</script>
