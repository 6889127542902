<!--
 * @Author: 陈远锋
 * @Date: 2022-03-07 15:41:52
 * @LastEditTime: 2022-03-14 13:05:35
 * @FilePath: \qyOut\src\pages\member\batch_orders.vue
 * @Description: Do not edit
-->
<template>
    <div>
        <Breadcrumb :style="{ margin: '20px 10px' }">
            <BreadcrumbItem>订单管理</BreadcrumbItem>
            <BreadcrumbItem>批量订单列表</BreadcrumbItem>
        </Breadcrumb>
        <loading :loading="loading" :error="error"></loading>
        <DatePicker style="margin: 10px;" type="daterange" placeholder="请选择查询时间" @on-change="dateOnChange"></DatePicker>
        <Table border stripe :columns="columns1" :data="orders">
            <template slot-scope="{ row }" slot="name">
                <strong>{{ row.name }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click="show(row, index)">修改</Button>
            </template>
        </Table>
        <Page style="margin: 10px; text-align:center;" @on-change="pageOnChange" :total="pages.total" :page-size="pages.per_page" show-elevator prev-text="上一页" next-text="下一页" />
    </div>
</template>
<script>
import expandRow from './batch_orders_expand.vue';
import loading from '../loading.vue';
export default {
    components: { loading },
    data () {
        return {
            loading: false,
            error: null,
            orders: [],
            pages: {},
            selectForm: {},	// 搜索条件
            columns1: [
                {
                    type: 'expand',
                    width: 50,
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row.order
                            }
                        })
                    }
                },
                { title: "时间", key: "updated_at", align: "center" },
                { align: "center", title: '产品名', key: 'goods_name' },
                { align: "center", title: '订单号', key: 'order_no' },
                { align: "center", title: '合计数量', key: 'total_num', width: 100 },
                { align: "center", title: '合计金额', key: 'total', width: 100 },
                { align: "center", title: '成功数量', key: 'success_num', width: 100 },
                { align: "center", title: '失败数量', key: 'error_num', width: 100 }
            ],
        }
    },
    created () {
        this.fetchData('perpaidOrders/list');
    },
    methods: {
        pageOnChange: function (page) {
            console.log('page=>', page);
            this.pages.links.forEach(link => {
                console.log('link=>', link);
                if (link.label == page) {
                    this.fetchData(link.url);
                    return;
                }
            })
        },
        dateOnChange (date) {
            console.log('date', date);
            this.selectForm.start_date = date[0];
            this.selectForm.end_date = date[1];
            this.fetchData('perpaidOrders/list', this.selectForm);
        },
        fetchData: function (url, params) {
            let data = {};
            for (let key in params) {
                console.log('param[key],', params[key]);
                if (params[key] === undefined || params[key].length == 0) {
                    delete params[key];
                }
            }
            data.params = params;
            this.axios.get(url, data).then(res => {
                console.log('order/list res', res);
                this.orders = res.data.orders;
                this.pages = res.data.pages;
                this.orders.forEach(element => {
                    element._expanded = false;
                });
            })
        }
    }
}
</script>
