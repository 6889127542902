<!--
 * @Author: 陈远锋
 * @Date: 2022-03-08 15:36:51
 * @LastEditTime: 2022-04-03 12:25:11
 * @FilePath: \qyOut\src\pages\member\info.vue
 * @Description: Do not edit
-->

<template>
    <div style="padding: 10px; background: #f8f8f9">
        <Card title="用户资料" icon="ios-options" :padding="0" shadow style="width: 300px">
            <CellGroup>
                <Cell title="公司名称:" :extra="user.company.company_name" />
                <Cell title="公司简称:" :extra="user.company.short_name" />
                <Cell title="公司余额:" :extra="'￥' + user.company.balance" />
                <Cell title="公司授信:" :extra="'￥' + user.company.facility" />
                <Cell title="名称:" :extra="user.name" />
                <Cell title="绑定手机:" :extra="user.phone" />
                <Cell title="登陆账号: " :extra="user.account" />
                <Cell title="权限: " :extra="rolesName">
                </Cell>
            </CellGroup>
        </Card>
    </div>
</template>
<script>
export default {
    data () {
        return {
            user: {}
        }
    },
    computed: {
        rolesName: function () {
            let name = '';
            this.user.roles.forEach(role => {
                name += role.name + ","
            });
            return name.substring(0, name.length - 1);
        }
    },
    created () {
        this.fetchData();
    },
    methods: {
        fetchData: function () {
            this.axios.get('userInfo').then(res => {
                console.log('user res', res.data.user);
                this.user = res.data.user;
            })
        }
    }
}
</script>
