<!--
 * @Author: 陈远锋
 * @Date: 2022-03-04 13:11:06
 * @LastEditTime: 2022-04-02 13:25:24
 * @FilePath: \crmeb-h5d:\gnProject\qyOut\src\Login.vue
 * @Description: Do not edit
-->
<template>
    <Row style="margin-top: 60px">
        <Col span="4" offset="10">
        <Form ref="formItem" :rules="ruleInline" :model="formItem" :label-width="80">
            <FormItem label="账号" prop="account">
                <Input v-model="formItem.account" placeholder="请输入账号">
                <Icon type="ios-person-outline" slot="prepend"></Icon>
                </Input>
            </FormItem>
            <FormItem label="密码" prop="password">
                <Input type="password" v-model="formItem.password" placeholder="请输入密码">
                <Icon type="ios-lock-outline" slot="prepend"></Icon>
                </Input>
            </FormItem>
            <FormItem>
                <Button ref="button" type="primary" @click="handleSubmit('formItem')">登陆</Button>
            </FormItem>
        </Form>
        </Col>
    </Row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    data () {
        return {
            formItem: {
                account: "",
                password: "",
            },
            ruleInline: {
                account: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    {
                        type: "string",
                        min: 6,
                        message: "密码至少是6位",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: mapState([
        // 映射 this.count 为 store.state.count
        "isLogin",
    ]),
    methods: {
        handleSubmit (name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.axios.post("user/login", this.formItem).then((res) => {
                        console.log("login res", res);
                        if (res.data.status === 200) {
                            console.log("ok");

                            let isSuperAdmin = false;
                            res.data.user.roles.forEach(role => {
                                if (role.name === '超级管理员') {
                                    isSuperAdmin = true;
                                }
                            })
                            res.data.user.isSuperAdmin = isSuperAdmin;
                            this.$store.commit("LOGIN", res.data.user.token);
                            this.$store.commit("SETUID", res.data.user.id);
                            this.$store.commit("UPDATE_USERINFO", res.data.user);
                            this.$store.commit("UPDATE_BALANCE", res.data.user.company.balance);
                            this.$store.commit("UPDATE_VERSION", res.data.user.company.version);
                            this.$store.commit("UPDATE_COMPANY_INFO", res.data.user.company);
                            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.user.token;
                            this.$router.push("/pages/member_goods");
                            localStorage.setItem('menu', JSON.stringify(res.data.user.permissions));
                        } else if (res.data.status === 404) {
                            this.$Message.error("账号错误!");
                        } else {
                            console.log();
                            this.$Message.error(res.data.errors.error);
                            this.$Message.error(res.data.msg);
                        }
                    })
                        .catch((error) => {
                            console.log("error=>", error);
                            this.$Message.error(error);
                        });
                    //});
                } else {
                    this.$Message.error("error!");
                }
            });
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>

